import React, { Component } from "react";
import { Fade } from "react-reveal";
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import "./App.css";
import { navigateTo } from "gatsby";

console.log(process.env.GATSBY_REACTGAID);
ReactGA.initialize(process.env.GATSBY_REACTGAID, {
  debug: true,
  titleCase: false,
});

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isCurtainVisible: false,
			curtainColor: "#60e0eb"
		};
	}

	showCurtain(e, color, route) {
		this.setState((state, props) => ({
			isCurtainVisible: true,
			curtainColor: color
		}));
		setTimeout(() => {
			return navigateTo(`/${route}`);
		}, 1725);
	}

	render() {
		const curtainClass = this.state.isCurtainVisible
			? "curtain closeCurtain"
			: "curtain";
		const innerCurtainClass = this.state.isCurtainVisible
			? "inner-curtain close-inner-curtain"
			: "inner-curtain";
		const appOpacity = this.state.isCurtainVisible ? "App make-opaque" : "App";
		return (
			<div>
				<Helmet>
					<title>Saransh Barua | Home</title>
					<meta
						name="description"
						content="Portfolio, personal website of saransh barua"
					/>
					<meta
						name="keywords"
						cpntent="saransh, barua, portfolio, personal, website"
					/>
				</Helmet>
				<div
					className={curtainClass}
					style={{ backgroundColor: this.state.curtainColor }}
				/>
				<div className={innerCurtainClass} />
				<div
					className={appOpacity}
					style={{
						border: `12px solid #03a9f4`
					}}
				>
					<div className="avatar">
						<img
							src="/saranshAvatar.jpg"
							width="100%"
							height="100%"
							alt="Saransh avatar"
						/>
					</div>

					<header className="App-header">
						<span style={{ zIndex: 2 }}>
							Hi I'm
							<Fade collapse duration={1200} distance="50px">
								<span style={{ color: "#03a9f4" }}>
									Saransh Barua
									<span style={{ color: "#03a9f4" }}>.</span>
								</span>
							</Fade>
						</span>
						<p className="sub-heading">A frontend developer based in India.</p>
						<Fade bottom duration={700} delay={200} distance="50px">
							<p className="text">
								I develop creative experiences for the web and have a unique eye
								for design. With a keen interest in frontend development, I
								focus on optimising web apps and love all things JavaScript.
							</p>
						</Fade>

						<Fade bottom duration={700} delay={400} distance="50px">
							<p className="text">
								Currently, I am helping start-ups to mould their ideas into
								beautifully designed digital products. I'm known for my out of
								the box design thinking, smart working attitude and sense of
								humour.
							</p>
						</Fade>
					</header>
					<main className="work-container">
						<span>
							<Fade bottom duration={900} distance="50px">
								Work
							</Fade>
						</span>
						<Fade>
							<div
								className="project-container"
								style={{
									backgroundColor: "#03a9f4"
								}}
								onClick={e => this.showCurtain(e, "#03a9f4", "prototype-ai")}
							>
								<span className="project-name">PrototypeAI</span>
							</div>
						</Fade>
						<Fade>
							<div
								className="project-container"
								style={{
									backgroundColor: "#f2ac34"
								}}
								onClick={e =>
									this.showCurtain(e, "#f2ac34", "testing-tendulkar")
								}
							>
								<span>Testing Tendulkar</span>
							</div>
						</Fade>
						<Fade>
							<div
								className="project-container"
								style={{
									backgroundColor: "#1b486b"
								}}
								onClick={e =>
									this.showCurtain(e, "#1b486b", "impressions-2019")
								}
							>
								<span>Impressions 2019</span>
							</div>
						</Fade>
					</main>
					<footer>
						<div className="contact-links">
							<a
								href="https://www.linkedin.com/in/saranshbarua/"
								target="_blank"
								rel="noopener noreferrer"
								style={{ zIndex: 2 }}
							>
								<img
									style={{ zIndex: 2 }}
									src="/ln.svg"
									height="30px"
									alt="Linkedin"
								/>
							</a>
							<a
								href="https://github.com/saranshbarua"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img src="/gh.svg" height="30px" alt="Github" />
							</a>
							<a
								href="mailto:saranshbarua@gmail.com"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img src="/mail.svg" height="30px" alt="Email" />
							</a>
						</div>
					</footer>
				</div>
			</div>
		);
	}
}

export default App;
