import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import App from "../components/App"

const IndexPage = () => (
  <Layout>
    <SEO title="Saransh Barua | Home" />
    <App></App>
  </Layout>
)

export default IndexPage
